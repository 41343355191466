<template>
	<div>
		<div class="main"> 
			<v-text-field
			:value="dateToString(new Date(date))"
			prepend-inner-icon="mdi-calendar-month primary--text" 
			readonly
			dense
			class="mx-4"
			></v-text-field>
			
			<p class="ml-5 mt-3 mb-1 font-weight-medium">{{project}}</p>

			<v-window
				class="elevation-1"
				vertical
			>
			<div class="contentWrapper">
				<div class="timeInfoWrapper">
					<div>	
						<v-list-item-title class="timeLabel">At work since</v-list-item-title>
						<p v-if="atWorkSince" class="primary--text timeField">{{atWorkSince}}</p>
						<p v-else class="primary--text timeField">--:--</p>
					</div>
					<div>	
						<v-list-item-title class="timeLabel">At work until</v-list-item-title>
						<p v-if="atWorkUntil" class="primary--text timeField">{{atWorkUntil}}</p>
						<p v-else class="primary--text timeField">--:--</p>
					</div>
					<div>
						<v-list-item-title class="timeLabel">Worktime</v-list-item-title>
						<p v-if="workTime<0" class="red--text timeField">{{timeToString(workTime)}}</p>
						<p v-else class="primary--text timeField">{{timeToString(workTime)}}</p>
					</div>
				</div>

				<!-- Edit entering -->
				<v-container class="mt-5 mb-0" v-if="displayEntering">
					<v-row justify="center">
						<p>{{addRecordLabel}} entering</p>
						<span class="mb-3 ml-1" v-if="entering!=null && entering.date==yesterdayDate()">(yesterday)</span>
					</v-row>
					<v-row justify="center" class="my-0">
						<div class="timePickerTextFieldWrapper">
							<v-icon @click="enteringTime=setTime(0, 15, enteringTime)" class="mr-3">mdi-minus-circle</v-icon>
							<v-dialog
								ref="menu"
								v-model="menu"
								:return-value.sync="enteringTime"
								persistent
								width="290px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="enteringTime"
										prepend-icon="mdi-clock-time-four-outline"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-time-picker
								v-if="menu"
								v-model="enteringTime"
								full-width
								>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="menu = false"
										class="timePickerButton"
									>
										Cancel
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.menu.save(enteringTime)"
									>
										OK
									</v-btn>
								</v-time-picker>
							</v-dialog>
							<v-icon class="ml-3" @click="enteringTime=setTime(1,15, enteringTime)">mdi-plus-circle</v-icon>
							<v-icon @click="enteringTime=setTime(1, 60, enteringTime)">mdi-plus-circle-multiple</v-icon>
						</div>
					</v-row>
				</v-container>

				<!-- Edit leaving -->
				<v-container v-if="displayLeaving" class="mt-5 mb-0">
					<v-row justify="center">
						<span class="mb-3">{{addRecordLabel}} leaving</span>
						<span class="mb-3 ml-1" v-if="leaving!=null && leaving.date==tomorrowDate()">(next day)</span>
						<span class="mb-3 ml-1" v-if="leaving!=null && leaving.date==yesterdayDate()">(yesterday)</span>
					</v-row>
					<v-row justify="center" class="my-0">
						<div class="timePickerTextFieldWrapper">
							<v-icon @click="leavingTime=setTime(0, 15, leavingTime)" class="mr-3">mdi-minus-circle</v-icon>
							<v-dialog
								ref="menu"
								v-model="menu"
								:return-value.sync="leavingTime"
								persistent
								width="290px"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-text-field
										v-model="leavingTime"
										prepend-icon="mdi-clock-time-four-outline"
										readonly
										v-bind="attrs"
										v-on="on"
									></v-text-field>
								</template>
								<v-time-picker
								v-if="menu"
								v-model="leavingTime"
								full-width
								>
									<v-spacer></v-spacer>
									<v-btn
										text
										color="primary"
										@click="menu = false"
										class="timePickerButton"
									>
										Cancel
									</v-btn>
									<v-btn
										text
										color="primary"
										@click="$refs.menu.save(leavingTime)"
									>
										OK
									</v-btn>
								</v-time-picker>
							</v-dialog>
							<v-icon class="ml-3" @click="leavingTime=setTime(1,15, leavingTime)">mdi-plus-circle</v-icon>
							<v-icon @click="leavingTime=setTime(1, 60, leavingTime)">mdi-plus-circle-multiple</v-icon>
						</div>
					</v-row>
				</v-container>

				<div class="buttonWrapper" @click="save">
					<v-btn class="white--text py-5 confirmButton" :color="buttonColor">{{buttonMessage}}</v-btn>
				</div>
			</div>
			</v-window>
			<v-dialog
			v-model="errorDialog.model"
			width="300"
		>
			<v-card>
				<v-card-title>
				{{ errorDialog.errorTitle }}
				</v-card-title>

				<v-card-text>
							{{ errorDialog.errorMessage }}
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="errorDialog.model = false"
				>
					OK
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		</div>
	</div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'

export default {

	name: 'AddLeaving',

	data: function(){
		return{
			date: "",
			menu: false,
			enteringTime: null,
			leavingTime: null,
			mutableWorker:this.worker,
			mutableEdit: this.edit,
			errorDialog: {
				errorTitle: "Error occured",
				errorMessage: "An error occured",
				model: false
			}
		}
	},
	props: {
		worker: Object,
		edit: {
			type:Boolean,
			default: undefined
		}

	},
	computed:{
		addRecordLabel(){
			if(this.mutableEdit) return "Edit"
			return "Add"
		},
		buttonMessage(){
			if(this.mutableEdit) return "Save changes"
			return this.atWorkSince? " Confirm Leaving": " Confirm Entering"
		},
		buttonColor(){
			if(this.mutableEdit) return "primary"
			return this.atWorkSince? "red": "green"
		},
		atWorkSince(){
			if(this.mutableWorker){
				if(this.mutableWorker.currentRecord && this.mutableWorker.currentRecord.type==0){
					return this.mutableWorker.currentRecord.time
				}
				if(this.mutableWorker.nextRecord && this.mutableWorker.nextRecord.type==0){
					return this.mutableWorker.nextRecord.time
				}
			}
			return null
		},
		atWorkUntil(){
			if(this.mutableWorker && this.mutableWorker.nextRecord){
				if(this.mutableWorker.nextRecord.type==1){
					return this.mutableWorker.nextRecord.time
				}
			}
			return null
		},
		entering(){
			if(this.mutableWorker.currentRecord && this.mutableWorker.currentRecord.type==0){
				return this.mutableWorker.currentRecord
			}
			else{
				if(this.mutableWorker.nextRecord && this.mutableWorker.nextRecord.type==0){
					return this.mutableWorker.nextRecord
				}
			}
			return {
				date: this.date
			}
		},
		leaving(){
			if(this.mutableWorker.nextRecord && this.mutableWorker.nextRecord.type==1){
				return this.mutableWorker.nextRecord
			}
			return {
				date: this.date
			}
		},
		project(){
			if(this.mutableWorker){
				return this.mutableWorker.projectName
			}
			return ""
		},
		workTime(){
			if(this.mutableEdit && this.entering.time && !this.leaving.time){
				return 0
			}
			if(this.enteringTime){
				return this.countWorkTime()
			}
			return 0
		},
		displayEntering(){
			if(this.mutableWorker){
				if(this.mutableEdit){
					if(this.mutableWorker.currentRecord){
						if(this.mutableWorker.currentRecord.type==0){
							return true
						}
					}
					if(this.mutableWorker.nextRecord && this.mutableWorker.nextRecord.type==0){
						return true
					}
				}
				else{
					if(this.mutableWorker.currentRecord){
						if(this.mutableWorker.currentRecord.type==1 && (!this.mutableWorker.nextRecord || this.mutableWorker.nextRecord.type==1)){
							return true
						}
					}
					else if(!this.mutableWorker.nextRecord || this.mutableWorker.nextRecord.type==1){
						return true
					}
				}
				return false
			}
			return false
		},
		displayLeaving(){
			if(this.mutableWorker){
				if(this.mutableEdit){
					if(this.mutableWorker.nextRecord && this.mutableWorker.nextRecord.type==1){
						return true
					}
				}
				else{
					if(this.mutableWorker.currentRecord){
						if(this.mutableWorker.currentRecord.type==0 && (this.mutableWorker.nextRecord==null || this.mutableWorker.nextRecord.type==0)){
							return true
						}
					}
				}
				return false
			}
			return false
		}
	},
	mounted(){
		if(!localStorage.getItem('tenantSlug')){
			this.$router.push('/choose-tenant');
			return
		}
		this.$emit("set-state", "default");
		this.date=this.getDateWithTimezone(new Date())
		if(this.mutableWorker){
			localStorage.worker=JSON.stringify(this.mutableWorker)
		}
		else{
			this.mutableWorker=JSON.parse(localStorage.worker)
		}
		if(this.edit===undefined){
			this.mutableEdit=JSON.parse(localStorage.edit)
		}
		else{
			this.mutableEdit=this.edit
			localStorage.edit=JSON.stringify(this.mutableEdit)
		}
		if(this.atWorkSince){
			this.enteringTime=this.atWorkSince	
		}
		else{
			this.enteringTime=this.setCurrentTime()
		}
		if(this.atWorkUntil){
			this.leavingTime=this.atWorkUntil
		}
		else{
			this.leavingTime=this.setCurrentTime()
		}
		this.setTitle()
		this.getGoBackLink()
	},
	watch: {
		enteringTime(){
			if(this.entering && this.leaving){
				if(this.leavingTime<this.enteringTime){
					let newLeavingDate = new Date()
					newLeavingDate.setDate((new Date(this.entering.date)).getDate()+1)
					this.leaving.date = this.getDateWithTimezone(newLeavingDate)
				}
				else{
					this.leaving.date = this.entering.date
				}
			}
		},
		leavingTime(){
			if(this.entering && this.leaving){
				if(this.leavingTime<this.enteringTime){
					let newLeavingDate = new Date()
					newLeavingDate.setDate((new Date(this.entering.date)).getDate()+1)
					this.leaving.date = this.getDateWithTimezone(newLeavingDate)
				}
				else{
					this.leaving.date = this.entering.date
				}
			}
		}
	},
	methods: {
		getDateWithTimezone(date){
			let currentDate=new Date(date)
			let year=currentDate.getFullYear()
			let month=currentDate.getMonth()+1
			let day = currentDate.getDate()
			if(month<10){
				month="0"+month
			}
			if(day<10){
				day="0"+day
			}
			return year+"-"+month+"-"+day
		},
		setTitle() {
			this.$emit('set-title', this.mutableWorker.fullName)
		},
		getGoBackLink() {
			this.$emit('getGoBackLink', "/presence");
		},
		setCurrentTime(){
			let today = new Date()
			let hours=today.getHours()
			if(hours<10) hours = "0"+hours
			let minutes = today.getMinutes()
			minutes = minutes - minutes%15
			if(minutes<10) minutes = "0"+minutes
			return hours+":"+minutes
		},
		setTime(type, minutes, time){
			let timeValues = time.split(":")
			if(type==0){
				timeValues[1]=parseInt(timeValues[1])-minutes
				if(timeValues[1]<0){
					timeValues[0]=parseInt(timeValues[0])-1
					timeValues[1]=parseInt(timeValues[1])+60
				}
				if(timeValues[0]<0){
					timeValues[0]=parseInt(timeValues[0])+24
				}
			}
			else if(type==1){
				timeValues[1]=parseInt(timeValues[1])+minutes
				if(timeValues[1]>59){
					timeValues[0]=parseInt(timeValues[0])+1
					timeValues[1]=parseInt(timeValues[1])-60
				}
				if(timeValues[0]>23){
					timeValues[0]=parseInt(timeValues[0])-24
				}
			}
			if(timeValues[0].toString().length<2){
				timeValues[0]="0"+timeValues[0]
			}
			if(timeValues[1].toString().length<2){
				timeValues[1]="0"+timeValues[1]
			}
			return timeValues[0]+":"+timeValues[1]
		},

		countWorkTime(){
			let time_end = new Date(this.leaving.date)
			let time_start= new Date(this.entering.date)
			let value_start = this.enteringTime.split(':');
			let value_end=this.leavingTime.split(':');
			time_start.setHours(value_start[0], value_start[1], 0, 0)
			time_end.setHours(value_end[0], value_end[1], 0, 0)
			return (time_end-time_start)
		},
		timeToString(time){
			if(!time){
				return '--:--'
			}
			let negativeTime=false
			if(time<0){
				time=Math.abs(time)
				negativeTime=true
			}
			let hours = Math.floor(time/1000/3600)
			let minutes = Math.floor((time%3600000)/1000/60)
			if(hours<10){
				hours="0"+hours
			}
			if(minutes<10){
				minutes="0"+minutes
			}	
			if(negativeTime)return "-"+hours+":"+minutes
			return hours+":"+minutes
		},
		dateToString(date){
			if(!date){
				return ''
			}
			let month = date.getMonth()+1
			if(month<10){
				month = "0"+month
			}
			let day = date.getDate()
			if(day<10){
				day = "0"+day
			}
			return day+"."+month+"."+date.getFullYear()
		},
		yesterdayDate(){
			if(this.date){
				let today = new Date(this.date), yesterday = new Date(this.date)
				yesterday.setDate(today.getDate()-1)
				return yesterday.toISOString().substr(0, 10)
			}
		},
		tomorrowDate(){
			if(this.date){
				let today = new Date(this.date), tomorrow = new Date(this.date)
				tomorrow.setDate(today.getDate()+1)
				return tomorrow.toISOString().substr(0, 10)
			}
		},
		saveEntering(){
			let urlString = "/api/v1/terminals/projects/cards/"
			if(this.mutableEdit){
				urlString+=this.entering.id
			}

			let dateToSave =new Date(this.date)
			//Wybór daty do zapisu
			if(!this.mutableEdit){
				//jeśli jest planowane wyjście i godzina wyjścia jest wcześniejsza niż godzina wejścia - zapisz na dzień przed wyjściem
				if(this.leaving && this.enteringTime>this.leaving.time){
					dateToSave.setDate((new Date(this.leaving.date)).getDate()-1)
				}
			}
			else if(this.entering){
				dateToSave=new Date(this.entering.date)
			}
			let requestType = this.mutableEdit? 'patch': 'post'
			this.$emit("set-state",  "LOADING_OVERLAYER", "Saving...");
			axios({
				method: requestType,
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + urlString,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					"type": 0,
					"project_id": this.mutableWorker.projectId,
					"worker_id": this.mutableWorker.workerId,
					"registered_at": dateToSave.toISOString().substr(0, 10)+" "+this.enteringTime
				}
			}).then(() => {
				this.$emit("set-state",  "DEFAULT");
				if(!this.leaving.time || !this.mutableEdit){
					this.$router.push({name: 'status'})
				}

			}).catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.$emit('display-error', "Application failed to connect to the server. Please check your internet connection");
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('display-error', "You do not have priviledges to this action");
						return;
					case 429:
						this.$emit('display-error', "Server received too many reuqests from your browser. Please wait a minute before trying again");
						return;
					case 400:
						this.$emit('display-error', "Server rejected your request. Please make sure your application is up to date (reload the website)");
						return;
					case 500:
						this.$emit('display-error', "An internal server error has occured. Please report this message to the administrator");
						return;
					default:
						this.$emit('display-error', "An unknown error has occured. That's all we know");
						return;
				}
			})
		},
		saveLeaving(){
			let urlString = "/api/v1/terminals/projects/cards/"
			let dateToSave =new Date(this.date)
			//Wybór daty do zapisu
			if(!this.mutableEdit){
				//jeżeli godzina wejścia jest późniejsza niż wyjścia-zapisz na dzień po wejściu
				if(this.entering.time>this.leavingTime){
					dateToSave.setDate((new Date(this.entering.date)).getDate()+1)
				}
			}
			else if(this.leaving){
				dateToSave=new Date(this.leaving.date)
			}
			if(this.mutableEdit){
				urlString+=this.leaving.id
			}
			let requestType = this.mutableEdit? 'patch': 'post'
			this.$emit("set-state",  "LOADING_OVERLAYER", "Saving...");
			axios({
				method: requestType,
				url: appConfig.getApiUrl(localStorage.getItem("tenantSlug")) + urlString,
				headers: {
					"Content-Type": "application/json",
					"Authorization": "Bearer " + localStorage.getItem("jwt")
				},
				data: {
					"type": 1,
					"project_id": this.mutableWorker.projectId,
					"worker_id": this.mutableWorker.workerId,
					"registered_at": dateToSave.toISOString().substr(0, 10)+" "+this.leavingTime
				}
			}).then(() => {
				this.$emit("set-state",  "DEFAULT");
				this.$router.push({name: 'status'})

			}).catch((error) => {
				this.$emit("set-state",  "DEFAULT");
				if (typeof(error.response) === 'undefined'){
					this.$emit('display-error', "Application failed to connect to the server. Please check your internet connection");
					return;
				}

				switch(error.response.status){
					case 401:
						// Redirect to login page
						this.$router.push("/");
						return;
					case 403:
						this.$emit('display-error', "You do not have priviledges to this action");
						return;
					case 429:
						this.$emit('display-error', "Server received too many reuqests from your browser. Please wait a minute before trying again");
						return;
					case 400:
						this.$emit('display-error', "Server rejected your request. Please make sure your application is up to date (reload the website)");
						return;
					case 500:
						this.$emit('display-error', "An internal server error has occured. Please report this message to the administrator");
						return;
					default:
						this.$emit('display-error', "An unknown error has occured. That's all we know");
						return;
				}
			})
		},
		save(){
			if(this.mutableEdit){
				if(this.entering.time){
					this.saveEntering()
				}
				if(this.leaving.time){
					this.saveLeaving()
				}
			}
			else{
				if(this.entering.time){
					this.saveLeaving()
				}
				else{
					this.saveEntering()
				}
			}
		}

	}

	};
</script>

<style scoped>

.dateWrapper{
	display: flex;
	margin-left:20px;
}

.contentWrapper{
	max-width: 450px;
	max-height: 650px;
	height: fit-content;
	overflow: visible;
}

.v-window{
	max-width: 450px;
	height: fit-content;
	padding-bottom: 30px;
	margin-left: 3%;
	margin-right: 3%;
}


.buttonWrapper{
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.timeLabel{
	font-size: 14px;
}

.timeField{
	font-size: 25px;
	margin-top: 5px;
}

.timePickerWrapper{
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.timePickerButtonsWrapper{
	width: 100%;
}

.setTimeButtonsWrapper{
	display: flex;
}

.confirmTimeButtonsWraper{
	display: flex;
	justify-content: center;
	margin-top: 5px;
}

.timePickerButton{
	width: fit-content;
}

.confirmButton {
	width: 200px;
}

.timePickerTextFieldWrapper{
	width: 200px;
	display: flex;
}

.timeInfoWrapper{
	display: flex;
	justify-content: space-between;
	padding: 30px;
}

.setTimeWrapper{
	display: flex;
}

.v-text-field >>> input{
	font-size: 1.2em;
}
</style>